import * as React from 'react';
import type { NextPage, GetServerSideProps } from 'next';
import { Panel } from '@components/panel';
import { Box } from '@components/box';
import { styled } from '@styles/stitches.config';
import { Heading, Text } from '@components/typography';
import { Button } from '@components/button';
import { Logo as StaterLogoIcon } from '@components/logo';
import { signIn } from 'next-auth/react';
import Head from 'next/head';
import { pageTitle } from '@utils/string';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { DEFAULT_LOCALE } from '@src/constants';
import { useTranslation } from 'next-i18next';
import { getToken } from 'next-auth/jwt';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getToken({ req: ctx.req });

  if (session?.user !== undefined) {
    return {
      redirect: {
        destination: `/`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ['Login'])),
    },
  };
};

const LoginPage: NextPage = () => {
  const { t, i18n } = useTranslation('Login');

  React.useEffect(() => {
    void i18n.reloadResources(i18n.resolvedLanguage, ['Login']);
  }, [i18n]);

  return (
    <>
      <Head>
        <title>{pageTitle('Login')}</title>
      </Head>
      <LoginContainer>
        <Logo width={248} height={57} />
        <Box css={{ maxWidth: '800px', marginInline: 'auto', paddingInline: '$4', spaceY: '$4' }}>
          <Panel>
            <InnerPanel>
              <Heading as="h1" variant="h2" css={{ color: '$primary400' }}>
                {t('login.title')}
              </Heading>
              <Text as="p" variant="default" css={{ mt: '$4', mb: '$10' }}>
                {t('login.welcome')}
              </Text>

              <Button onClick={() => signIn('keycloak')} id="login-button" css={{ width: '100%' }}>
                {t('login.button.signin')}
              </Button>
            </InnerPanel>
          </Panel>
        </Box>
      </LoginContainer>
    </>
  );
};

export default LoginPage;

const LoginContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const InnerPanel = styled('div', {
  width: '100%',
  py: '$10',
  px: '$14',

  '@lg': {
    py: '$20',
    px: '$24',
  },
});

const Logo = styled(StaterLogoIcon, {
  my: '$20',
});
