import * as React from 'react';
import { styled } from '@styles/stitches.config';
import { Heading } from '@components/typography';
import { InfoIcon } from '@components/icons';

type PanelHeaderProps = {
  title: string;
  after?: React.ReactNode;
};

function PanelHeader({ title, after, ...props }: PanelHeaderProps): JSX.Element {
  return (
    <StyledPanelHeader {...props}>
      <Heading variant="h3">{title}</Heading>
      {after}
    </StyledPanelHeader>
  );
}

type NoResultsProp = {
  title: string;
  after?: React.ReactNode;
};

function NoResults({ title, after }: NoResultsProp): JSX.Element {
  return (
    <NoResultsWrapper>
      <IconNoticeWrapper>
        <NoticeIcon aria-hidden>
          <InfoIcon width={16} height={16} />
        </NoticeIcon>
        <NoticeText>{title}</NoticeText>
      </IconNoticeWrapper>
      {after}
    </NoResultsWrapper>
  );
}

const StyledPanel = styled('section', {
  background: '$white',
  boxShadow: '$1',
  borderRadius: '$2',
  overflow: 'hidden',
});

export const StyledPanelHeader = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  background: '$primary50',
  py: '$3',
  px: '$4',
  '@md': {
    py: '$3',
    px: '$8',
  },
});

const NoResultsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  px: '$8',
  pb: '$8',
  pt: '$4',
});

const NoticeIcon = styled('div', {
  display: 'flex',
  borderRadius: '$full',
  p: '$3',
  boxShadow: '$1',
  strokeWidth: '2',
  color: '$notification200',
  backgroundColor: '$notification50',
});

const IconNoticeWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  spaceX: '$4',
  width: '100%',
});

const NoticeText = styled('p', {
  marginLeft: '$4',
  fontWeight: 500,
  fontSize: '$sm',
  color: '$notification200',
});

const PanelBody = styled('div', {
  p: '$6',
  '@md': {
    p: '$8',
  },
});

export const Panel = Object.assign(StyledPanel, {
  Header: PanelHeader,
  Body: PanelBody,
  NoResults: NoResults,
});
